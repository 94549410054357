/*global Iodine, isInViewport*/
import '@kingshott/iodine';

export default {
    data() {
        return {
            formChanged: false,
            formState: null,
            submitClicked: false,
            success: null,
            error: null,
        };
    },
    mounted() {
        Iodine.addRule('password', (value) => {
            const passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
            return passwordRegex.test(value);
        });

        Iodine.addRule('less', (value, param) => {
            return parseInt(value) <= param;
        });

        Iodine.messages['password'] = `Value must be at least 8 characters long, contains upper & lower case letters, digits and special characters`;
        Iodine.messages['less'] = 'Value must be [PARAM] or less';
    },
    methods: {
        initValidation() {
            this.validation.inputElements = [...this.$el.querySelectorAll('[data-rules]')];

            this.resetBlur();
        },
        resetBlur() {
            //Create an object attached to the component state for each input element to store its state
            this.validation.inputElements.map((ele) => {
                this.validation[ele.name].blurred = false;
            });
        },
        getErrorMessage(ele) {
            //Check using iodine and return the error message only if the element has not been blurred
            let error = true;
            if (ele.dataset.rules != '') {
                error = Iodine.is(ele.value, JSON.parse(ele.dataset.rules));
            }

            if (error !== true && this.validation[ele.name].blurred) {
                return Iodine.getErrorMessage(error);
            }
            //return empty string if there are no errors
            return '';
        },
        updateErrorMessages() {
            //map throught the input elements and set the 'errorMessage'
            this.validation.inputElements.map((ele) => {
                this.validation[ele.name].errorMessage = this.getErrorMessage(ele);
            });
        },
        submit(event) {
            this.submitClicked = true;
            //Since its an ajx form, it shouldn't submit normally.
            event.preventDefault();
            const invalidElements = this.validation.inputElements.filter((input) => {
                if (input.dataset.rules != '') {
                    return Iodine.is(input.value, JSON.parse(input.dataset.rules)) !== true;
                }

                return false;
            });

            if (invalidElements.length > 0) {
                
                if (!isInViewport(document.getElementById(invalidElements[0].id))) {
                    document.getElementById(invalidElements[0].id).scrollIntoView({ behavior: 'smooth' });
                }
                //We set all the inputs as blurred if the form has been submitted
                this.validation.inputElements.map((input) => {
                    this.validation[input.name].blurred = true;
                });
                //And update the error messages.
                this.updateErrorMessages();
            } else {
                this.formState = 'loading';
                this.send();
            }
        },
        change(event) {
            //Ignore all events that aren't coming from the inputs we're watching

            if (!this.validation[event.target.name]) {
                return false;
            }

            this.formChanged = true;

            if (event.type === 'focusout' && this.submitClicked) {
                this.validation[event.target.name].blurred = true;
            }
            //Whether blurred or on input, we update the error messages
            this.updateErrorMessages();
        },
    },
};
