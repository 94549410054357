<template>

  <div id="bookingActions" class="booking-form-section booking-actions" :class="{ unlocked: $store.state.booking.unlockedSteps.includes('bookingActions') }">
    <div class="content" :class="{'payment': $store.state.booking.readyForPayment}">
      
      <div class="booking-error" v-if="error">
        <div class="error">{{ error }}</div>
      </div>

      <div id="booking_actions" class="box actions">
        <a href="#" class="btn book" @click.prevent="book()">Book</a>
      </div>

      <div class="box payments sas">
        <a :href="paymentURL" class="btn pay">Pay AED {{total}}</a>
        <div class="note" v-html="getLocale('bookingActionNote')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getLocale from '@/mixins/getLocale';
export default {
  name: 'Actions',
  mixins: [getLocale],
  data() {
    return {
      error: null,
      paymentURL: null,
      total: null
    };
  },
  mounted() {},
  methods: {
    book() {
      //document.querySelector('.booking').classList.add('loading');
     // this.$emit('setBookingLoader', true);
      this.error = null;
      // console.log(this.$store.state.booking.data);//return;
      let bookingData = {
        collectionDate: this.$store?.state?.booking?.data?.collectionTime?.date ?? '2024-09-17',
        collectionTime: this.$store?.state?.booking?.data?.collectionTime?.time ?? '13:27:31',
        collectionAddressId: this.$store?.state?.booking?.data?.collectionAddress ?? '123',
        destinationAddressId: this.$store?.state?.booking?.data?.collectionAddress ?? '123',
        // destinationAddressId: this.$store?.state?.booking?.data?.destinationAddress ?? '123',
        selectedCompanyId: this.$store?.state?.booking?.data?.deliveryService ?? '19fe9abf-363e-11ec-b66b-f23c91aa6e6f',
        packages: this.$store?.state?.booking?.data?.packages ?? '123',
        contactForInsurance: this.$store?.state?.booking?.data?.contactForInsurance ? "yes" : "no",
        successRedirectURL: window.location.origin + '/order-confirmation',
        failureRedirectURL: window.location.origin + '/order-failure',
      };

      this.$store.dispatch('book', bookingData).then((data) => {
        //document.querySelector('.booking').classList.remove('loading');
        this.$emit('setBookingLoader', false);
        this.paymentURL = data.paymentURL;
        this.total = data.total;
        
        this.$store.dispatch('readyForPayment', true);

      }).catch((error) => {
        this.$emit('setBookingLoader', false);
        this.error = error;
      });
    }
  },
};
</script>

<style lang="scss">
a.btn.book {
  display: none !important;
}
.sas {
  display: none !important;
}
</style>