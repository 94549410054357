<template>
  <div class="container xl">
    <div class="main-cols">
      <div class="left-menu-col">
        <LeftMenu />
      </div>
      <div class="right-content-col">
        <h5 style="font-size: 36px; font-weight: bold; background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%) text; -webkit-text-fill-color: transparent; margin: 0px;">Booking</h5>
        <div id="bookingCollection" class="booking-form-section booking-collection" 
          :class="{ 'new-address': showNewAddressForm, 'unlocked': $store.state.booking.unlockedSteps.includes('bookingCollection') }">
          <div class="booking-step-title-wrapper">
            <h4 class="title">Collection</h4>
          </div>
          <div class="content">
            <div class="cols">
              <div class="left">
                <CollectionAddress @show-new-address-form="setShowNewAddressForm" />
              </div>
              <!-- <div class="right"> -->
                <!-- <CollectionTime /> -->
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionAddress from '@/components/booking/CollectionAddress';
//import CollectionTime from '@/components/booking/CollectionTime';
import LeftMenu from '@/components/LeftMenu';

export default {
  name: 'Collection',
  components: {
    LeftMenu,
    CollectionAddress,
    //CollectionTime,
  },
  data() {
    return {
      showNewAddressForm: false,
    };
  },
  mounted() {
    this.$store.dispatch('resetBookingData');
  },
  methods: {
    setShowNewAddressForm(showNewAddressForm) {
      this.showNewAddressForm = showNewAddressForm;
    },
  },
};
</script>