<template>
  <div class="container xl" style="padding: 0;">
    <Quote />
  </div>
</template>

<script>
import Quote from '@/components/quote/Quote.vue';

export default {
  name: 'QuoteView',
  components: {
    Quote,
  }
};
</script>
