<template>
  <div class="container xl">
    <div class="main-cols">
      <div class="left-menu-col">
        <LeftMenu />
      </div>
      <div class="right-content-col">
        <Quote />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu';
import Quote from '@/components/quote/Quote.vue';

export default {
  name: 'QuoteView',
  components: {
    LeftMenu,
    Quote,
  }
};
</script>
